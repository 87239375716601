import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { Box } from "@mui/material";


export type GraphKey = {id: number | string | null, color: string, name: string};
export type ActiveGraphKey = GraphKey & {active: boolean};

type LegendProps = {
  keys: ActiveGraphKey[];
  handleSetActiveKeys: (key: ActiveGraphKey) => void
  getKeyName?: (name: string) => string
};

const Legend = ({keys, getKeyName, handleSetActiveKeys}: LegendProps) => {
  return <Box 
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{ whiteSpace: "nowrap"}}
    maxWidth={800}
    flexWrap="wrap"
  >
    {keys.map(key => {
      return <Box 
        key={key.id}
        display="flex"
        alignItems="center"
        sx={{ whiteSpace: "nowrap", marginRight: 2, marginBottom: 1}}
      >
        <CabToggleChip
          chipColor={key.color}
          label={getKeyName ? getKeyName(key.name) :  key.name}
          selected={key.active}
          onClick={() => {
            handleSetActiveKeys(key);
          }}
        />
      </Box>;
    })}
  </Box>;
};

export default Legend;