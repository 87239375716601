import { useEffect, useState, useMemo, useCallback, memo, useRef } from "react";
import { Grid, Box, Typography, styled, FormControl, FormLabel } from "@mui/material";
import { EventAnalysisIndexBy, EventAnalysisKeyBy, EventAnalyticsState, 
  ExecutiveContactCategory, ExecutiveContactType, User } from "../../../store";
import { DateTime } from "luxon";
import { CSVLink } from "react-csv";
import colors from "../../../colors";
import {
  Leader, EventCategory, ConsolidatedLocation,
  ExecutiveContact, EventType, EventAnalysisInterval, EventAnalysisResponse,
  ContactAnalysisResponse
} from "../../../store";
import ReconciliationDatePicker from "../ReconciliationDatePicker/ReconciliationDatePicker";
import { CabAvatar, CabCollapseMenu, CabDropdown, CabExecPicker, CabIcon, CabModal } from "@CabComponents/index";
import ContactDataGrid from "../ContactDataGrid/ContactDataGrid";
import AnalyticsBarChart from "../AnalyticsBarChart/AnalyticsBarChart";
import AnalyticsPieChart from "../AnalyticsPieChart/AnalyticsPieChart";
import { DEFAULT_LEADER_LABEL_COLORS } from "../../../constants";
import { IoDownloadOutline } from 'react-icons/io5';
import { GridSortModel, GridFilterModel, GridCallbackDetails } from "@mui/x-data-grid-pro";
import AnalyticsTab from "../../../pages/Settings/AnalyticsTab";
import { getLeaderIconSrc } from "../../../utils/leaderUtils";
import generatePDF, { Margin } from "react-to-pdf";
import InfoToolTip from "../../Common/InfoToolTip";

// This entire component is memoized, in order to refresh the table with new data you must setLoading to true
// before dispatching the update, then set to false forcing the table to refresh (setLoading in hooks.tsx)

export type AnalyticsInsightsProps = {
  myLeaders: Leader[];
  selectedLeader: Leader | null;
  onLeaderSelect: (leader: number | null) => void;
  selectPlaceholder?: string;
  execPickerDisabled?: boolean;
  event_categories: { [key: number]: EventCategory };
  event_types: { [key: number]: EventType };
  consolidated_locations: { [key: number]: ConsolidatedLocation };
  recurrence_types: EventAnalyticsState["recurrence_types"]
  executive_contacts: { [key: number]: ExecutiveContact };
  loading: boolean;
  initialLoading: boolean;
  handleChangeDates: (startDate: DateTime | undefined, endDate: DateTime | undefined) => void
  start: DateTime | undefined;
  end: DateTime | undefined;
  user: User | null | undefined;
  tz?: string;
  indexBy: EventAnalysisIndexBy;
  keyBy: EventAnalysisKeyBy;
  interval: EventAnalysisInterval;
  analyticsEventResponseData: EventAnalysisResponse | undefined;
  analyticsContactResponseData: ContactAnalysisResponse["data"] | undefined;
  executive_contact_categories: { [key: string]: ExecutiveContactCategory };
  contact_types: {[key: string]: ExecutiveContactType};
  handleUpdateExecutiveContact: (data: Partial<ExecutiveContact> & { id: number }) => void;
  handleSetIndexBy: (value: EventAnalysisIndexBy) => void;
  handleSetKeyBy: (value: EventAnalysisKeyBy) => void;
  handleSetInterval: (value: EventAnalysisInterval) => void;
  editContact: (attendeeId: number) => void
  handleSortModelChange: (sortModel: GridSortModel) => void;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails) => void;
  contactDataRows:{
    attendee: {
      id: number;
      name: string;
      email: string | null;
    };
    hours: number;
    category: number;
    contactType: number;
  }[]
};

type CSVEventRecord = {
  period: string;
  hours: number;
  [key: string]: string | number
};

type CSVContactRecord = {
  name: string
  title: string
  primary_email: string | null
  contact_category: string | null
  hours: number
};

const StyledBox = styled(Box, {label: "StyledBox"})(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  height: "40px",
  marginLeft: 1,
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingRight: "12px",
  paddingLeft: "12px",
  borderRadius: "4px",
  color: colors.white900,
  textDecoration: "none"
}));

const AnalyticsInsights = ({
  start, end, tz, user, event_categories, loading,
  event_types, consolidated_locations, executive_contacts, selectedLeader, onLeaderSelect, selectPlaceholder,
  execPickerDisabled, myLeaders, analyticsEventResponseData, analyticsContactResponseData, indexBy, interval,
  executive_contact_categories, handleUpdateExecutiveContact, keyBy, handleSetKeyBy, handleSetInterval,
  editContact, recurrence_types, handleSortModelChange, handleFilterModelChange, contact_types,
  initialLoading, contactDataRows, handleChangeDates
}: AnalyticsInsightsProps) => {

  const [csvEventData, setCSVEventData] = useState<CSVEventRecord[]>([]);
  const [csvContactData, setCSVContactData] = useState<CSVContactRecord[]>([]);
  const [editLabelsModelOpen, setEditLabelsModalOpen] = useState(false);
  const [renderForExport, setRenderForExport] = useState(false);
  const targetRef = useRef(null);

  const toPDF = async () => {
    setRenderForExport(true);
    // Some nominal delay is needed to allow the DOM to update before generating the PDF
    setTimeout(() => {
      generatePDF(targetRef, {
        "filename": "Cabinet-Analytics.pdf",
        page: {
          margin: Margin.SMALL,
        },
        overrides: {
          canvas: {
            useCORS: true
          }
        }
      });

      setRenderForExport(false);
    }, 500);
  };
  
  const getKeyBySet = useCallback((): {[key: string]: {id: number, name: string, color: string}} => {
    switch (keyBy) {
      case EventAnalysisKeyBy.CATEGORY_KEY:
        return {...event_categories, "-3": {id: -3, name: "None", color: DEFAULT_LEADER_LABEL_COLORS[4]}};
      case EventAnalysisKeyBy.EVENT_TYPE_KEY:
        return {...event_types, "-3": {id: -3, name: "None", color: DEFAULT_LEADER_LABEL_COLORS[4]}};
      case EventAnalysisKeyBy.RECURRENCE_TYPE_KEY:
        return {...recurrence_types, "-3": {id: -3, name: "None", color: DEFAULT_LEADER_LABEL_COLORS[4]}};
      case EventAnalysisKeyBy.LOCATION_KEY:
        return {...consolidated_locations, "-3": {id: -3, name: "None", color: DEFAULT_LEADER_LABEL_COLORS[4]}};
    }
  }, [consolidated_locations, event_categories, event_types, keyBy, recurrence_types]);

  useEffect(() => {
    const data: CSVEventRecord[] = [];
    const keyBySet = getKeyBySet();
    Object.values(analyticsEventResponseData?.data || {}).forEach(row => {
      row.data.forEach(record => {
        if (keyBySet[String(record.id).toString()]) {
          const keyByValue = keyBySet[JSON.stringify(record.id)];
          if (keyByValue !== undefined) {
            data.push({
              period: record.period,
              hours: record.hours,
              [keyBy]: keyByValue.name
            });
          }
        }
      });
    });
    setCSVEventData(data);
  }, [
    event_categories, executive_contacts, event_types, consolidated_locations,
    analyticsEventResponseData?.data, executive_contact_categories, getKeyBySet, keyBy
  ]);

  useEffect(() => {
    const data: CSVContactRecord[] = [];
    analyticsContactResponseData?.forEach(row => {
      let category = null;
      const category_id = executive_contacts[row.attendee]?.contact_category;
      if (category_id) {
        category = executive_contact_categories[category_id] || null;
      }
      data.push({
        name: executive_contacts[row.attendee]?.name,
        title: executive_contacts[row.attendee]?.title,
        primary_email: executive_contacts[row.attendee]?.primary_email,
        contact_category: category?.name || null,
        hours: row.hours
      });
      setCSVContactData(data);
    });
  }, [executive_contact_categories, executive_contacts, analyticsContactResponseData]);

  const cabExecOptions = useMemo(() => myLeaders.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [myLeaders]);

  const handleLeaderSelect = (leaderId: number | number[] | null | undefined) => {
    if (!Array.isArray(leaderId)) {
      onLeaderSelect(leaderId || null);
    }
  };

  const eventColorTransform = useMemo(() => {
    const colorMap: { [key: string]: string } = {};
    Object.values(analyticsEventResponseData?.keys || {}).forEach((key) => {
      colorMap[key.name] = key.color;
    });
    return colorMap;
  }, [analyticsEventResponseData?.keys]);

  const diffGreaterThanOneYear = useMemo(() => start && end ? end.diff(start, 'years').years > 1 : false, [start, end]);
  const diffGreaterThanTwoMonths = useMemo(
    () => start && end ? end.diff(start, 'months').months > 2 : false, [start, end]
  );

  const minDate = analyticsEventResponseData?.aggregates.min_start_date;
  const maxDate = analyticsEventResponseData?.aggregates.max_end_date;


  return <Box paddingLeft={2} paddingRight={2} paddingBottom={8} paddingTop={2} ref={targetRef}>
    <Box paddingBottom={1} >
      <Box display="flex" flexDirection={{xs: 'column', lg: 'row'}} justifyContent="space-between" marginBottom={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1} flexWrap="wrap">
          <Box marginRight={2} marginBottom={1}>
            <Typography>Showing insights for</Typography>
            <CabExecPicker<number>
              value={selectedLeader?.id || null}
              options={cabExecOptions}
              onChange={v => handleLeaderSelect(v)}
              placeholder={selectPlaceholder}
              disabled={execPickerDisabled}
              sx={{ maxWidth: '100%', "& .select-value-label": {fontWeight: "bold", fontSize: 20} }}
            />
          </Box>

          <Box 
            display="flex" 
            alignItems='flex-end' 
            flexWrap='wrap' 
            gap={1} 
            justifyContent={{ xs: 'flex-start', lg: 'flex-end'}} 
            marginBottom={1}
          >
            <FormControl>
              <FormLabel>Interval</FormLabel>
              <CabDropdown<EventAnalysisInterval>
                value={interval}
                onChange={(e) => handleSetInterval(e.target?.value as EventAnalysisInterval)}
                options={[
                  { 
                    value: EventAnalysisInterval.DAY_INTERVAL, 
                    label: "Daily",
                    disabled: diffGreaterThanTwoMonths,
                    tooltipText: diffGreaterThanTwoMonths ? 
                      "Daily interval is not available for date ranges greater than two months" : 
                      undefined 
                  },
                  { 
                    value: EventAnalysisInterval.WEEK_INTERVAL,
                    label: "Weekly",
                    disabled: diffGreaterThanOneYear,
                    tooltipText: diffGreaterThanOneYear ? 
                      "Weekly interval is not available for date ranges greater than one year" : undefined 
                  },
                  { value: EventAnalysisInterval.MONTH_INTERVAL, label: "Monthly" },
                  { value: EventAnalysisInterval.QUARTER_INTERVAL, label: "Quarterly" },
                  { value: EventAnalysisInterval.ANNUAL_INTERVAL, label: "Annually" },
                ]}
                sx={renderForExport ? {"fieldset": {border: "unset"}} : {}}
                hideArrow={renderForExport}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Breakdown</FormLabel>
              <CabDropdown<EventAnalysisKeyBy>
                value={keyBy}
                onChange={(e) => handleSetKeyBy(e.target?.value as EventAnalysisKeyBy)}
                options={[
                  { value: EventAnalysisKeyBy.CATEGORY_KEY, label: "Category" },
                  { value: EventAnalysisKeyBy.EVENT_TYPE_KEY, label: "Type" },
                  { value: EventAnalysisKeyBy.LOCATION_KEY, label: "Location" },
                  { value: EventAnalysisKeyBy.RECURRENCE_TYPE_KEY, label: "Recurrence" },
                ]}
                sx={renderForExport ? {"fieldset": {border: "unset"}} : {}}
                hideArrow={renderForExport}
              />
            </FormControl>
            <ReconciliationDatePicker
              start={start}
              end={end}
              handleChangeDates={handleChangeDates}
              tz={tz}
              interval={interval}
              setInterval={handleSetInterval}
              minDate={
                minDate ?
                  DateTime.fromISO(minDate) : undefined
              }
              maxDate={
                maxDate ?
                  DateTime.fromISO(maxDate) : undefined
              }
              sx={renderForExport ? {"button": {border: "unset"}} : {}}
              hideArrow={renderForExport}
            />
            {!renderForExport && <CabCollapseMenu
              popOverTitle="Insights Export"
              passedAnchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              popOverSx={{
                
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  backgroundColor: "transparent"
                }
              }}
              passedTransformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              target={<StyledBox>
                <CabIcon sx={{ fontSize: 20, marginRight: .5 }} Icon={IoDownloadOutline} />
                Export
              </StyledBox>}
              buttons={<>
                <CSVLink
                  data={csvEventData}
                  style={{ textDecoration: "none" }}
                  filename={`event-insights-${DateTime.now().setZone(tz).toISO()}.csv`}
                >
                  <StyledBox sx={{backgroundColor: colors.forest950, marginTop: 1}}>
                    Event Data
                  </StyledBox>
                </CSVLink>
                <CSVLink
                  data={csvContactData}
                  style={{ textDecoration: "none" }}
                  filename={`contact-insights-${DateTime.now().setZone(tz).toISO()}.csv`}
                >
                  <StyledBox sx={{backgroundColor: colors.forest950, marginTop: 1}}>
                    Contact Data
                  </StyledBox>
                </CSVLink>
                <StyledBox 
                  sx={{
                    backgroundColor: colors.forest950, 
                    marginTop: 1, 
                    '&:hover': {cursor: "pointer"}
                  }} 
                  onClick={() => toPDF()}
                >
                  PDF
                </StyledBox>
              </>}
            />}
          </Box>
        </Box>
      </Box>
    </Box>
    {initialLoading && !(!!maxDate && !!minDate) ? (
      <></>
    ) : (
      <Box position="relative">
        <Grid container height={600} alignContent="start">
          <Grid item xs={12} sx={{ fontWeight: "bold", fontSize: 20, marginBottom: 2 }}>
            <Box display={"flex"} gap={1}>
              Time in meetings
              <InfoToolTip
                message="All-day events are excluded from this graph because they do not have defined durations."
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ 
            border: 2,
            borderColor: colors.black100,
            borderRadius: 1,
            paddingTop: 2,
            height: 500 }
          }>
            {analyticsEventResponseData && <AnalyticsBarChart
              indexBy={indexBy}
              interval={interval}
              responseData={analyticsEventResponseData}
              colorTransform={eventColorTransform}
              keyBy={keyBy}
              loading={loading}
            />}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box display="flex" flexDirection="column" height={'100%'}>
              <Typography sx={{ fontWeight: "bold", fontSize: 20, marginBottom: 0 }}>
                Most engaged with contacts
              </Typography>
              <Box flex={1} minHeight={'450px'}>
                <ContactDataGrid
                  rows={contactDataRows}
                  executive_contacts={executive_contacts}
                  executive_contact_categories={executive_contact_categories}
                  contact_types={contact_types}
                  handleUpdateExecutiveContact={handleUpdateExecutiveContact}
                  user={user}
                  tz={tz}
                  editContact={editContact}
                  loading={loading}
                  handleSortModelChange={handleSortModelChange}
                  handleFilterModelChange={handleFilterModelChange}
                  handleEditLabelModal={() => setEditLabelsModalOpen(true)}
                  renderForExport={renderForExport}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box display="flex" flexDirection="column" height={'100%'}>
              <Typography sx={{ 
                fontWeight: "bold",
                fontSize: 20,
                marginBottom: {xs: 2, md: renderForExport ? "16" : "31px"},
                marginTop: {xs: 1, md:0}
              }}>
                Time breakdown
              </Typography>
              <Box
                flex={1}
                sx={{ border: 2, borderColor: colors.black100, borderRadius: 1, paddingTop: 2 }}
              >
                {analyticsEventResponseData && <AnalyticsPieChart
                  colorTransform={eventColorTransform}
                  responseData={analyticsEventResponseData}
                  keyBy={keyBy}
                  chartHeight={400}
                  loading={loading}
                />}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )}
    <CabModal
      open={editLabelsModelOpen}
      closeIcon
      onClose={() => setEditLabelsModalOpen(false)}
      sx={{ ".MuiPaper-root": { width: "100%" } }}
      title="Calendar Analytics"
    >
      <AnalyticsTab />
    </CabModal>
  </Box>;
};

//export default AnalyticsInsights;

export default memo(AnalyticsInsights, ({loading: loadingPrev}, {loading: loadingNext}) => {  
  return loadingNext === loadingPrev;
});