import { CabButton, CabDropdown, CabIcon, CabToggleChip, CabTooltip } from "@CabComponents/index";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import {
  GridCallbackDetails, GridColDef,
  GridFilterModel, GridRenderCellParams, GridSortModel, GridToolbarColumnsButton, GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import { IoCreateOutline } from 'react-icons/io5';
import { ReactNode, useCallback, useMemo } from "react";
import colors from "../../../colors";
import { ExecutiveContact, ExecutiveContactCategory, ExecutiveContactType, User } from "../../../store";
import { containsOperator, numberOperator, selectFilterOperator } from "../../DataGrid/utils";
import NoRowOverlay from "../NoRowsOverlay";
import { createSelectOptions } from "../utils";
import CabDataGrid from "@CabComponents/CabDataGrid";


type RowData = {
  attendee: {
    id: number;
    name?: string;
    email?: string | null;
  };
  hours: number;
  category: number;
  contactType: number;
};

// Ensures that PDF exporting fits nicely on 1 page
const MAX_EXPORT_ROWS = 12;
const MAX_PAGE_SIZE = 12;

export type ContactDataGridProps = {
  rows: RowData[];
  executive_contacts: { [key: string]: ExecutiveContact };
  user: User | undefined | null;
  executive_contact_categories: { [key: string]: ExecutiveContactCategory };
  contact_types: { [key: string]: ExecutiveContactType };
  handleUpdateExecutiveContact: (data: Partial<ExecutiveContact> & { id: number }) => void;
  tz?: string;
  editContact: (attendeeId: number) => void;
  loading: boolean;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails) => void;
  handleEditLabelModal: () => void;
  renderForExport: boolean;
};

export const ContactDataGrid = ({
  rows, executive_contacts, executive_contact_categories, user, handleUpdateExecutiveContact, tz,
  editContact, loading, handleSortModelChange, handleFilterModelChange, contact_types,
  handleEditLabelModal, renderForExport
}: ContactDataGridProps) => {

  const updateForeignKey = useCallback((
    e: SelectChangeEvent<number>,
    id: number,
    attribute: "contact_category" | "contact_type"
  ) => {
    let value = e.target?.value ? Number(e.target?.value) : null;
    value = value === -1 ? null : value;
    handleUpdateExecutiveContact(
      { id: id, [attribute]: value }
    );
  }, [handleUpdateExecutiveContact]);

  const renderContactName = useCallback((
    { row: { attendee } }: GridRenderCellParams<RowData, ReactNode>
  ): ReactNode => {
    const disabled = !(user?.features.CALENDAR_ANALYTICS && user?.permissions.ANALYTICS_LABELS);
    const contact_name = attendee?.name ? attendee.name : attendee?.email;
    const toolTipText = attendee?.name ? `${attendee.name} - ${attendee.email}` : attendee?.email;
    return <Box display="flex" flexWrap="nowrap" width="100%" justifyContent="space-between" alignItems="center">
      <CabTooltip title={toolTipText} wrapWithSpan>
        <Typography variant="body2" sx={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: 230 }}>
          {contact_name}
        </Typography>
      </CabTooltip>
      {!disabled && !renderForExport &&
        <CabIcon 
          Icon={IoCreateOutline}
          onClick={() => editContact(attendee?.id)}
        />
      }
    </Box>;
  }, [
    editContact,
    user?.features.CALENDAR_ANALYTICS,
    user?.permissions.ANALYTICS_LABELS,
    renderForExport
  ]);

  const renderCategory = useCallback((
    { row: { attendee, category: cat } }: GridRenderCellParams<RowData, ReactNode>
  ): ReactNode => {
    const options = createSelectOptions(
      Object.values(executive_contact_categories),
      user?.permissions.ANALYTICS_LABELS
    );
    const category = executive_contact_categories[cat];
    const disabled = !(user?.features.CALENDAR_ANALYTICS && user?.permissions.ANALYTICS_LABELS);
    if (disabled) {
      const eventId = category?.id || -1;
      const eventTypeObject = eventId > 0 ?
        executive_contact_categories[eventId] : { id: -1, name: "None", color: colors.white800 };
      return <CabToggleChip 
        chipColor={eventTypeObject.color} 
        label={eventTypeObject.name} 
        selected={true} 
        renderForExport={renderForExport}
      />;
    } else {
      return <CabDropdown<number>
        options={options}
        value={category?.id || -1}
        onChange={(e) => updateForeignKey(e, attendee.id, "contact_category")}
        overrides={{
          renderValue: (value: unknown) => {
            const eventId = value as number;
            const eventTypeObject = eventId > 0 ?
              executive_contact_categories[eventId] : { id: -1, name: "None", color: colors.white800 };
            return <CabToggleChip 
              chipColor={eventTypeObject.color} 
              label={eventTypeObject.name} 
              selected={true} 
              renderForExport={renderForExport}
            />;
          },
          sx: {
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0
            },
          }
        }}
      />;
    }
  }, [
    executive_contact_categories,
    updateForeignKey,
    user?.features.CALENDAR_ANALYTICS,
    user?.permissions.ANALYTICS_LABELS,
    renderForExport
  ]);

  const renderType = useCallback((
    { row: { attendee, contactType } }: GridRenderCellParams<RowData, ReactNode>
  ): ReactNode => {
    const options = createSelectOptions(Object.values(contact_types), user?.permissions.ANALYTICS_LABELS, true);
    const category = contact_types[contactType];

    return <CabDropdown<number>
      options={options}
      value={category?.id || -1}
      onChange={(e) => updateForeignKey(e, attendee.id, "contact_type")}
      overrides={{
        renderValue: (value: unknown) => {
          const eventId = value as number;
          const eventTypeObject = eventId > 0 ?
            contact_types[eventId] : { id: -1, name: "None", color: colors.white800 };
          return <CabToggleChip 
            chipColor={eventTypeObject.color} 
            label={eventTypeObject.name} 
            selected={true} 
            renderForExport={renderForExport}
          />;
        },
        sx: {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
        }
      }}
    />;
  }, [contact_types, user?.permissions.ANALYTICS_LABELS, updateForeignKey, renderForExport]);

  const filteringEnabled = true;

  const executiveContactCategoryOptions = useMemo(
    () => createSelectOptions(
      Object.values(executive_contact_categories), user?.permissions.ANALYTICS_LABELS, true),
    [executive_contact_categories, user?.permissions.ANALYTICS_LABELS]
  );

  const executiveContactTypeOptions = useMemo(
    () => createSelectOptions(
      Object.values(contact_types), user?.permissions.ANALYTICS_LABELS, true),
    [contact_types, user?.permissions.ANALYTICS_LABELS]
  );

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'contact_lookup',
      headerName: 'Contact',
      width: 280,
      editable: false,
      display: "flex",
      filterable: filteringEnabled,
      sortable: filteringEnabled,
      renderCell: renderContactName,
      filterOperators: containsOperator,
      headerAlign: 'left'
    },
    {
      field: 'hours',
      headerName: 'Hours',
      type: 'number',
      width: 60,
      editable: false,
      display: "flex",
      filterable: filteringEnabled,
      sortable: filteringEnabled,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value, row, column, apiRef) => Math.round(value * 10) / 10,
      filterOperators: numberOperator([])
    },
    {
      field: 'contact_category',
      headerName: 'Relationship',
      width: renderForExport ? 240 : 140,
      editable: false,
      filterable: filteringEnabled,
      sortable: filteringEnabled,
      display: "flex",
      renderCell: renderCategory,
      headerAlign: 'left',
      filterOperators: selectFilterOperator([
        { options: executiveContactCategoryOptions, rawOptions: executive_contact_categories }
      ]).map(operator => {
        return {
          ...operator,
          getValueAsString: (value: number) => {
            if (Array.isArray(value)) {
              return value.map(v => Object.values(executive_contact_categories)
                .find(e => e.id === v)?.name || 'None').join(', ');
            }
            return Object.values(executive_contact_categories).find(e => e.id === value)?.name || 'None';
          }
        };
      })
    },
    {
      field: 'contact_type',
      headerName: renderForExport ? "" : 'Contact Type',
      width: renderForExport ? 0 : 140,
      minWidth: 0,
      editable: false,
      filterable: filteringEnabled,
      sortable: filteringEnabled,
      display: "flex",
      renderCell: renderForExport ? undefined : renderType,
      headerAlign: 'left',
      filterOperators: selectFilterOperator([
        { options: executiveContactTypeOptions, rawOptions: contact_types }
      ]).map(operator => {
        return {
          ...operator,
          getValueAsString: (value: number) => {
            if (Array.isArray(value)) {
              return value.map(v => Object.values(contact_types)
                .find(e => e.id === v)?.name || 'None').join(', ');
            }
            return Object.values(contact_types).find(e => e.id === value)?.name || 'None';
          }
        };
      })
    },
  ], [executiveContactCategoryOptions, executiveContactTypeOptions, executive_contact_categories,
    contact_types, filteringEnabled, renderCategory, renderContactName, renderType, renderForExport]);

  const initialState = useMemo(() => ({
    pagination: { 
      paginationModel: { 
        pageSize: rows && rows.length > MAX_PAGE_SIZE ? MAX_PAGE_SIZE : rows.length,
      }
    },
  }), [rows]);

  const dataRows = useMemo(() => ( 
    rows?.filter(row => executive_contacts[row.attendee.id]?.is_resource === false) ?? [] 
  ), [executive_contacts, rows]);

  const pageSizeOptions = useMemo(() => (
    rows?.length !== undefined ? [rows.length > MAX_PAGE_SIZE ? MAX_PAGE_SIZE : rows.length] : [0]
  ), [rows.length]);

  const slots = useMemo(() => ({
    noRowsOverlay: () => (
      <NoRowOverlay emptyMessage="No Contact Data Available" msTimeout={10000} />
    ),
    toolbar: filteringEnabled && !renderForExport 
      ? () => <Box display="flex" flexDirection="row-reverse" sx={{ width: "100%" }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <CabButton
          icon={<CabIcon
            alt='Labels'
            size='small'
            Icon={IoCreateOutline}
          />}
          onClick={handleEditLabelModal}
          buttonType="tertiary"
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            border: 0,
            paddingRight: 1,
            paddingLeft: 1,
            "&:hover": {
              border: 0
            }
          }}
        >
          Labels
        </CabButton>
      </Box> : () => <Box height="16px" width="100%"></Box>
  }), [filteringEnabled, handleEditLabelModal, renderForExport]);

  return (
    <CabDataGrid
      initialState={initialState}
      rows={renderForExport ? dataRows.slice(0, MAX_EXPORT_ROWS) : dataRows}
      columns={columns}
      loading={loading}
      paginationMode="client"
      filterMode="server"
      sortingMode="server"
      pagination
      getRowId={(row) => row.attendee.id}
      pageSizeOptions={pageSizeOptions}
      onSortModelChange={handleSortModelChange}
      onFilterModelChange={handleFilterModelChange}
      hideFooter
      disableVirtualization
      slots={slots}
    />
  );
};

export default ContactDataGrid;